@import url('https://fonts.bunny.net/css2?family=Outfit:wght@100..900&display=swap&family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans;
  background: #fafafa;
}

h1 {
  @apply font-sans font-bold;
}

h3 {
  @apply font-mono font-bold text-xl;
}